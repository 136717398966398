import memoize from 'lodash-es/memoize';
import { createFbSDKScriptEl } from './createFbSDKScriptEl';

window.cfIsFbSdkInited = window.cfIsFbSdkInited || false;

const initSdk = (appId: string) => {
  window.FB.init({
    appId,
    autoLogAppEvents: true,
    xfbml: false,
    version: 'v20.0',
  });
  window.cfIsFbSdkInited = true;
};

export const initFbSdk = memoize(
  (appId: string, sdkSrc?: string) =>
    new Promise((resolve, reject) => {
      const asyncResolve = () => {
        setTimeout(() => {
          resolve(true);
        });
      };

      if (window.cfIsFbSdkInited) {
        asyncResolve();
        return;
      }

      const fbAsyncInitProperties = Object.getOwnPropertyDescriptor(
        window,
        'fbAsyncInit',
      );

      let fbApiLoadInterval: number;
      if (fbAsyncInitProperties && !fbAsyncInitProperties.writable) {
        fbApiLoadInterval = window.setInterval(() => {
          if (window.FB) {
            initSdk(appId);
            clearInterval(fbApiLoadInterval);
            asyncResolve();
          }
        }, 100);
      } else {
        const oldFbAsyncInit = window.fbAsyncInit;
        window.fbAsyncInit = () => {
          if (oldFbAsyncInit) {
            oldFbAsyncInit();
          }
          initSdk(appId);
          asyncResolve();
        };
      }

      setTimeout(() => {
        if (window.FB && !window.cfIsFbSdkInited) {
          initSdk(appId);
          asyncResolve();
        } else {
          reject(new Error('error fb sdk initialize'));
        }
        clearInterval(fbApiLoadInterval);
      }, 7000);

      setTimeout(() => {
        createFbSDKScriptEl(sdkSrc);
      });
    }),
);
